import { createSlice } from '@reduxjs/toolkit';

const createEventsReducer = createSlice({
    name: 'createEventsReducer',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        loadingCreateEvents(state) {
            state.loading = true;
            state.error = null;
        },
        successCreateEvents(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        failureCreateEvents(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { loadingCreateEvents, successCreateEvents, failureCreateEvents } = createEventsReducer.actions;
export const stateCreateEvents = (state) => state.createEventsReducer
export default createEventsReducer.reducer;