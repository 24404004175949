import React, { useState, Suspense } from 'react';
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";

import PrivateRoute from './privateRoute';
import LazyLoading from "../components-shared/lazy-loading";

const LoginPage = React.lazy(() => import('../modules/login/pages/home'));
const LoginAccountPage = React.lazy(() => import('../modules/login/pages/account'));
const LoginPasswordPage = React.lazy(() => import('../modules/login/pages/password'));

const DashboardPage = React.lazy(() => import('../modules/dashboard/pages/home'));
const PageAccount = React.lazy(() => import('../modules/account/pages/home'));

const EventsPage = React.lazy(() => import('../modules/events/pages/home'));
const EventsCreate = React.lazy(() => import('../modules/events/pages/create'));

const PaymentsPage = React.lazy(() => import('../modules/payments/pages/home'));
const SupportPage = React.lazy(() => import('../modules/support/pages/home'));

const NotFound = React.lazy(() => import('../components-shared/not-found'));
const LayoutLogin = React.lazy(() => import('../components-shared/layout-login'));
const Layout = React.lazy(() => import('../components-shared/layout'));


const Router = () => {

    return (
        <Suspense fallback={<LazyLoading />}>
            <Routes>
                <Route path="/login" element={<LayoutLogin />}>
                    <Route
                        index
                        element={<LoginPage />}
                    />
                    <Route
                        path="conta"
                        element={<LoginAccountPage />}
                    />
                    <Route
                        path="senha"
                        element={<LoginPasswordPage />}
                    />
                </Route>

                <Route path="/" element={<Layout />}>
                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <DashboardPage />
                            </PrivateRoute>
                        }
                    />
                </Route>

                <Route path="/conta" element={<Layout />}>
                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <PageAccount />
                            </PrivateRoute>
                        }
                    />
                </Route>

                <Route path="/eventos" element={<Layout />}>
                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <EventsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='criar'
                        element={
                            <PrivateRoute>
                                <EventsCreate />
                            </PrivateRoute>
                        }
                    />
                </Route>

                <Route path="/pagamentos" element={<Layout />}>
                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <PaymentsPage />
                            </PrivateRoute>
                        }
                    />
                </Route>

                <Route path="/ajuda" element={<Layout />}>
                    <Route
                        index
                        element={
                            <PrivateRoute>
                                <SupportPage />
                            </PrivateRoute>
                        }
                    />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    )
}

export default Router
