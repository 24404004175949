import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    //middleware: [...getDefaultMiddleware(), sagaMiddleware],
    middleware: getDefaultMiddleware => [ ...getDefaultMiddleware(), sagaMiddleware ],

});

sagaMiddleware.run(rootSaga);

export default store;