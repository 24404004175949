import { call, put, takeEvery } from "redux-saga/effects";
import { loadingRegister, failureRegister, successRegister } from "../reducers/register-reducer";
import { GET, LOGIN, REGISTER } from "../../../../services/api";
import { toast } from "react-toastify";
import { failureLogout, loadingLogout, successLogout } from "../reducers/logout-reducer";
import { failureLogin, loadingLogin, successLogin } from "../reducers/login-reducer";


export function* loginRegister(action) {
    yield put({ type: loadingRegister.type, })
    const result = yield call(REGISTER, { data: action.payload })
    console.log("result", result)
    if (result.data.error == false) {
        yield put({ type: failureRegister.type, })
    } else {
        localStorage.setItem("events@login", JSON.stringify(result.data.data.user))
        yield put({ type: successRegister.type, })
        toast.success("OK!!!!")
    }
}

export function* loginLogout(action) {
    yield put({ type: loadingLogout.type, })
    const result = yield call(GET, { endpoint: "/logout" })
    console.log("result", result)
    if (result.data.error == false) {
        yield put({ type: failureLogout.type, })
    } else {
        localStorage.removeItem("events@login")
        yield put({ type: successLogout.type, })
        toast.success("OK!!!!")
    }
}

export function* login(action) {
    yield put({ type: loadingLogin.type, })
    const result = yield call(LOGIN, { endpoint: `/login?email=${action.payload.email}&password=${action.payload.password}` })
    console.log("result", result)
    if (result.data.error == false) {
        yield put({ type: failureLogin.type, })
    } else {
        localStorage.setItem("events@login", JSON.stringify(result.data.data.user))
        yield put({ type: successLogin.type, })
        toast.success("OK!!!!")
    }
}