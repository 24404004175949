import { createSlice } from '@reduxjs/toolkit';

const registerReducer = createSlice({
    name: 'registerReducer',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        loadingRegister(state) {
            state.loading = true;
            state.error = null;
        },
        successRegister(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        failureRegister(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { loadingRegister, successRegister, failureRegister } = registerReducer.actions;
export const stateRegister = (state) => state.registerReducer
export default registerReducer.reducer;