import { createSlice } from '@reduxjs/toolkit';

const loginReducer = createSlice({
    name: 'loginReducer',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        loadingLogin(state) {
            state.loading = true;
            state.error = null;
        },
        successLogin(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        failureLogin(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { loadingLogin, successLogin, failureLogin } = loginReducer.actions;
export const stateLogin = (state) => state.loginReducer
export default loginReducer.reducer;