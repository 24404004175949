import { all, takeLatest } from 'redux-saga/effects';
import { login, loginLogout, loginRegister } from '../modules/login/redux/saga';
import { createEvents, getEvents } from '../modules/events/redux/saga';


export default function* rootSaga() {
    yield all([
        takeLatest("saga/login/loginRegister", loginRegister),
        takeLatest("saga/login/loginLogout", loginLogout),
        takeLatest("saga/login/login", login),
        takeLatest("saga/events/getEvents", getEvents),
        takeLatest("saga/events/createEvents", createEvents),
    ]);
}