
import axios from "axios";

axios.defaults.headers.common['Accept'] = 'application/json'
//const url = process.env.REACT_APP_API_URL
const url = "https://api.acherole.com.br"
const axiosConfig = { baseURL: url };

const api = axios.create(axiosConfig);

export const POST = (data) => {

    console.log("data post", data.data)
    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('events@login'))?.token?.value
            }
        }).then(res => {
            return {
                error: false,
                data: res
            }
        }).catch(error => {
            return {
                error: true,
                data: error
            }
        })
    )
}

export const GET = (data) => {

    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('events@login'))?.token?.value
            }
        }).then(res => {
            return {
                error: false,
                data: res
            }
        }).catch(error => {
            return {
                error: true,
                data: error
            }
        })
    )
}


export const PUT = (data) => {

    return (
        api.put(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('events@login'))?.token?.value
            }
        }).then(res => {
            return res
        }).catch(error => {
            return false
        })
    )
}

export const DELETE = (data) => {

    return (
        api.delete(data.endpoint, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('events@login'))?.token?.value
            }
        }).then(res => {
            return res
        }).catch(error => {
            return false
        })
    )
}


export const REGISTER = (data) => {
    return (
        api.post("/register", data.data)
            .then(res => {
                return {
                    error: false,
                    data: res
                }
            }).catch(error => {
                return {
                    error: true,
                    data: error
                }
            })
    )
}

export const PASSWORD = (data) => {
    return (
        api.get(data.endpoint, data.data)
            .then(res => {
                return {
                    error: false,
                    data: res
                }
            }).catch(error => {
                return {
                    error: true,
                    data: error
                }
            })
    )
}

export const LOGIN = (data) => {
    return (
        api.get(data.endpoint, data.data)
            .then(res => {
                return {
                    error: false,
                    data: res
                }
            }).catch(error => {
                return {
                    error: true,
                    data: error
                }
            })
    )
}






