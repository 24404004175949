import Router from "./router";
import { register } from 'swiper/element/bundle';

function App() {
  register();
  return (
    <Router />
  );
}

export default App;
