export const theme = {
    colors: {
        red: "",
        blue: "",
        yellow: "",
        black: "",
        white: "",
        green: "",
        purple: "#503296",
        pink: "#FA3295",
        backgroundPrimary: '#ffffff',
        backgroundSecondary: '#F5F2FB',
        text: '#56545E',
    },
};