import { call, put } from "redux-saga/effects";
import { GET, POST } from "../../../../services/api";
import { failureGetEvents, loadingGetEvents, successGetEvents } from "../reducers/get-events-reducer";
import { toast } from "react-toastify";
import { failureCreateEvents, loadingCreateEvents, successCreateEvents } from "../reducers/create-events-reducer";


export function* getEvents(action) {
  yield put({ type: loadingGetEvents.type, })
  const result = yield call(GET, { endpoint: `/event?search=${action.payload.search}&order=${action.payload.order}&page=${action.payload.page}&per_page=${action.payload.perPage}&filter_city=${action.payload.filterCity}&filter_date=${action.payload.date}&filter_status=${action.payload.status}` })
  console.log("result", result)
  if (result.data.error == false) {
    yield put({ type: failureGetEvents.type, })
  } else {
    yield put({ type: successGetEvents.type, payload: result.data.data })
  }
}

export function* createEvents(action) {
  yield put({ type: loadingCreateEvents.type, })
  const result = yield call(POST, { endpoint: `/event`, data: action.payload })
  console.log("result", result)
  if (result.data.error == false) {
    yield put({ type: failureCreateEvents.type, })
  } else {
    yield put({ type: successCreateEvents.type, payload: result.data.data })
  }
}