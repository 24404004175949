import { combineReducers } from '@reduxjs/toolkit';
import registerReducer from '../modules/login/redux/reducers/register-reducer';
import logoutReducer from '../modules/login/redux/reducers/logout-reducer';
import loginReducer from '../modules/login/redux/reducers/login-reducer';
import getEventsReducer from '../modules/events/redux/reducers/get-events-reducer';
import createEventsReducer from '../modules/events/redux/reducers/create-events-reducer';

const rootReducer = combineReducers({
    registerReducer: registerReducer,
    logoutReducer: logoutReducer,
    loginReducer: loginReducer,
    getEventsReducer: getEventsReducer,
    createEventsReducer: createEventsReducer
});

export default rootReducer;
