import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    svg{
        width: 100px;
        height: 100px;

        animation: crescerDiminuir 15s infinite;
        @keyframes crescerDiminuir {
            0% {
                transform: scale(0.5);
            }
            50% {
                transform: scale(3);
            }
            100% {
                transform: scale(0.5);
            }
        }
    }
`