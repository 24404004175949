import { createSlice } from '@reduxjs/toolkit';

const logoutReducer = createSlice({
    name: 'logoutReducer',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        loadingLogout(state) {
            state.loading = true;
            state.error = null;
        },
        successLogout(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        failureLogout(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { loadingLogout, successLogout, failureLogout } = logoutReducer.actions;
export const stateLogout = (state) => state.logoutReducer
export default logoutReducer.reducer;