import React from 'react'
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {

    const isAuthenticated = () => {
        return localStorage.getItem('events@login') ? true : false
    }

    return (
        isAuthenticated() ? children : <Navigate to="/login" />
    )
}

export default PrivateRoute