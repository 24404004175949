import React from 'react'
import { Container } from './styled'
import SearchIcon from "../../assets/images/teste.svg";
import { BiSearchAlt } from 'react-icons/bi';

const LazyLoading = () => {
  return (
    <Container>
      <BiSearchAlt color={"#FA3295"} />
    </Container>
  )
}

export default LazyLoading