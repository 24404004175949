import { createSlice } from '@reduxjs/toolkit';

const getEventsReducer = createSlice({
    name: 'getEventsReducer',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        loadingGetEvents(state) {
            state.loading = true;
            state.error = null;
        },
        successGetEvents(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        failureGetEvents(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { loadingGetEvents, successGetEvents, failureGetEvents } = getEventsReducer.actions;
export const stateGetEvents = (state) => state.getEventsReducer
export default getEventsReducer.reducer;