import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Inter", sans-serif;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    color: ${({ theme }) => theme.colors.text};
    box-sizing: border-box;
  }

  *{
    margin: 0;
    padding: 0;
    font-size: 14px;
    
  }
`;